import { CanActivateFn, Router } from '@angular/router';
import { JwtService } from '../service/utils/jwt.service';
import { inject } from '@angular/core';

export const authRoleGuard: CanActivateFn = (route, state) => {
  const jwtService = inject(JwtService);

  const allowedRoles = route.data['roles'] as Array<string>;
  const userRole = jwtService.getUserRole();

  if (allowedRoles && userRole && allowedRoles.includes(userRole)) {
    return true;
  }

  return inject(Router).createUrlTree(['/unauthorized']);
}
